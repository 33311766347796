// Generated by ReScript, PLEASE EDIT WITH CARE


function serialize(t) {
  return t;
}

function parse(s) {
  if (!(s === "LAST_30MIN" || s === "LAST_24H" || s === "LAST_8H" || s === "LAST_7_DAYS" || s === "LAST_4H" || s === "LAST_1H" || s === "LAST_48H")) {
    return ;
  }
  switch (s) {
    case "LAST_1H" :
        return "LAST_1H";
    case "LAST_4H" :
        return "LAST_4H";
    case "LAST_7_DAYS" :
        return "LAST_7_DAYS";
    case "LAST_8H" :
        return "LAST_8H";
    case "LAST_24H" :
        return "LAST_24H";
    case "LAST_30MIN" :
        return "LAST_30MIN";
    case "LAST_48H" :
        return "LAST_48H";
    
  }
}

function toDisplayableString(s) {
  switch (s) {
    case "LAST_1H" :
        return "1 hour";
    case "LAST_4H" :
        return "4 hours";
    case "LAST_7_DAYS" :
        return "7 days";
    case "LAST_8H" :
        return "8 hours";
    case "LAST_24H" :
        return "24 hours";
    case "LAST_30MIN" :
        return "30 minutes";
    case "LAST_48H" :
        return "48 hours";
    
  }
}

var getDisplayList = [
  "LAST_30MIN",
  "LAST_1H",
  "LAST_4H",
  "LAST_8H",
  "LAST_24H",
  "LAST_48H",
  "LAST_7_DAYS"
];

export {
  serialize ,
  parse ,
  toDisplayableString ,
  getDisplayList ,
}
/* No side effect */
