// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RadarPageQuery_graphql from "../__generated__/RadarPageQuery_graphql.mjs";

function serialize(t) {
  var match;
  match = t.__$inputUnion === "published" ? [
      "published",
      t._0
    ] : [
      "score",
      t._0
    ];
  return match[0] + ":" + match[1];
}

function parse(s) {
  var match = s.split(":");
  if (match.length !== 2) {
    return ;
  }
  var prefix = match[0];
  var sortOrder = match[1];
  var sortOrder$1 = RadarPageQuery_graphql.Utils.sortOrder_fromString(sortOrder);
  if (sortOrder$1 === undefined) {
    return ;
  }
  switch (prefix) {
    case "published" :
        return {
                __$inputUnion: "published",
                _0: sortOrder$1
              };
    case "score" :
        return {
                __$inputUnion: "score",
                _0: sortOrder$1
              };
    default:
      return ;
  }
}

export {
  serialize ,
  parse ,
}
/* RadarPageQuery_graphql Not a pure module */
