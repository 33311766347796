// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function LoadingBar(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          className: "animate-progress w-full h-full bg-primary origin-left-right"
                        }),
                    className: "h-1 w-full overflow-hidden animate-fade-in"
                  }),
              className: "w-full"
            });
}

var make = LoadingBar;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
