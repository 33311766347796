// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ThemeProviderJsx from "./theme-provider.jsx";

var make = ThemeProviderJsx.ThemeProvider;

function useTheme(prim) {
  return ThemeProviderJsx.useTheme();
}

export {
  make ,
  useTheme ,
}
/* make Not a pure module */
