// Generated by ReScript, PLEASE EDIT WITH CARE

import * as TooltipJsx from "./tooltip.jsx";

var make = TooltipJsx.Tooltip;

var Tooltip = {
  make: make
};

var make$1 = TooltipJsx.TooltipTrigger;

var Trigger = {
  make: make$1
};

var make$2 = TooltipJsx.TooltipContent;

var Content = {
  make: make$2
};

var make$3 = TooltipJsx.TooltipProvider;

var Provider = {
  make: make$3
};

export {
  Tooltip ,
  Trigger ,
  Content ,
  Provider ,
}
/* make Not a pure module */
