// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Clerk from "../clerk/Clerk.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GraphqlSse from "graphql-sse";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as RelayRuntime from "relay-runtime";

var graphQLEndpoint = import.meta.env.VITE_GRAPHQL_API_ENDPOINT;

async function getToken() {
  var tokenRef = Clerk.Hooks.Auth.getTokenRef.contents;
  if (tokenRef !== undefined) {
    return Caml_option.nullable_to_opt(await tokenRef());
  }
  
}

async function fetchQuery(operation, variables, _cacheConfig, _uploads) {
  var authToken = await getToken();
  var headers = authToken !== undefined ? new Headers([
          [
            "content-type",
            "application/json"
          ],
          [
            "authorization",
            "Bearer " + authToken
          ]
        ]) : new Headers([[
            "content-type",
            "application/json"
          ]]);
  var res = await fetch(graphQLEndpoint, {
        method: "POST",
        body: Caml_option.some(Core__Option.getOr(JSON.stringify({
                      query: operation.text,
                      variables: variables
                    }), "")),
        headers: Caml_option.some(headers),
        credentials: "same-origin"
      });
  if (res.ok) {
    return await res.json();
  } else {
    return Js_exn.raiseError("API error.");
  }
}

var subscriptionsClient = GraphqlSse.createClient({
      url: graphQLEndpoint,
      headers: (async function () {
          var authToken = await getToken();
          if (authToken !== undefined) {
            return {
                    Authorization: "Bearer " + authToken
                  };
          }
          
        }),
      shouldRetry: (function (_event) {
          return true;
        })
    });

function subscriptionFunction(operation, variables, _cacheConfig) {
  var subscriptionQuery_operationName = operation.name;
  var subscriptionQuery_query = operation.text;
  var subscriptionQuery = {
    operationName: subscriptionQuery_operationName,
    query: subscriptionQuery_query,
    variables: variables
  };
  return RelayRuntime.Observable.create(function (sink) {
              var unsubscribe = subscriptionsClient.subscribe(subscriptionQuery, sink);
              return {
                      unsubscribe: unsubscribe,
                      closed: false
                    };
            });
}

export {
  graphQLEndpoint ,
  getToken ,
  fetchQuery ,
  subscriptionsClient ,
  subscriptionFunction ,
}
/* graphQLEndpoint Not a pure module */
