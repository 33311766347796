// Generated by ReScript, PLEASE EDIT WITH CARE


function serialize(t) {
  return t;
}

function parse(s) {
  if (!(s === "NORWEGIAN" || s === "ENGLISH" || s === "DANISH" || s === "SWEDISH")) {
    return ;
  }
  switch (s) {
    case "DANISH" :
        return "DANISH";
    case "ENGLISH" :
        return "ENGLISH";
    case "NORWEGIAN" :
        return "NORWEGIAN";
    case "SWEDISH" :
        return "SWEDISH";
    
  }
}

function toDisplayableString(s) {
  if (!(s === "NORWEGIAN" || s === "ENGLISH" || s === "DANISH" || s === "SWEDISH")) {
    return "-";
  }
  switch (s) {
    case "DANISH" :
        return "Danish";
    case "ENGLISH" :
        return "English";
    case "NORWEGIAN" :
        return "Norwegian";
    case "SWEDISH" :
        return "Swedish";
    
  }
}

function toDisplayableStringFromInput(s) {
  return toDisplayableString(s);
}

function toDisplayableAbbreviation(s) {
  if (!(s === "NORWEGIAN" || s === "ENGLISH" || s === "DANISH" || s === "SWEDISH")) {
    return "-";
  }
  switch (s) {
    case "DANISH" :
        return "DK";
    case "ENGLISH" :
        return "EN";
    case "NORWEGIAN" :
        return "NO";
    case "SWEDISH" :
        return "SE";
    
  }
}

export {
  serialize ,
  parse ,
  toDisplayableString ,
  toDisplayableStringFromInput ,
  toDisplayableAbbreviation ,
}
/* No side effect */
