// Generated by ReScript, PLEASE EDIT WITH CARE

import * as NetworkUtils from "./utils/NetworkUtils.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as RelayRuntime from "relay-runtime";

var preparedAssetsMap = {};

var network = RelayRuntime.Network.create(NetworkUtils.fetchQuery, NetworkUtils.subscriptionFunction);

var environment = RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(), 50, 21600000), undefined, undefined, undefined, undefined, undefined);

export {
  preparedAssetsMap ,
  network ,
  environment ,
}
/* network Not a pure module */
