// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(s) {
  var s$1 = parseFloat(s);
  if (!isNaN(s$1)) {
    return s$1;
  }
  
}

function serialize(v) {
  return v.toString();
}

export {
  parse ,
  serialize ,
}
/* No side effect */
