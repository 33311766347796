// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "Octopus" || str === "CitationStory" || str === "Published") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

function displayLabel(t) {
  switch (t) {
    case "CitationStory" :
        return "Citation Story";
    case "Octopus" :
        return "Draft";
    case "Published" :
        return "Published";
    
  }
}

export {
  parse ,
  serialize ,
  displayLabel ,
}
/* No side effect */
