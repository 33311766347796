// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ButtonJsx from "./button.jsx";

var make = ButtonJsx.Button;

export {
  make ,
}
/* make Not a pure module */
