// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SonnerJsx from "./sonner.jsx";

var make = SonnerJsx.Toaster;

var Toaster = {
  make: make
};

export {
  Toaster ,
}
/* make Not a pure module */
